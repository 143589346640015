(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsMarketplaceController", domainSettingsMarketplaceController);

    function domainSettingsMarketplaceController(
        $scope,
        $http,
        errorHandling,
        $rootScope,
        themesService,
        $translate,
        $mdDialog,
        userDataService,
        coreData,
        treeState,
        $timeout,
        $state
    ) {
        const vm = this;
        vm.isInitialized = false;
        vm.treeExpanded = treeState.isExpanded;
        vm.marketplaceItems = [];
        vm.bannerImage = "";
        vm.loadMarketplaceSettings = loadMarketplaceSettings;
        vm.openSettingsModal = openSettingsModal;
        vm.isOnlyOfficeEnabled = isOnlyOfficeEnabled;
        vm.domainAdminCanOverride = false;
        vm.lightordark;
        vm.configuredAccounts = [];
        vm.reloadvar = true;
        vm.isAdmin = $state.current.name.startsWith("index.domain-marketplace")
        vm.accountTypes = [
            {name: "dropbox", id: 2, sort:2},
            {name: "googledrive", id: 9, sort:2},
            {name: "leitzcloud", id: 7, sort:2},
            {name: "onedrive", id: 4, sort:2},
            {name: "owncloud", id: 10, sort:2},
            {name: "vboxx", id: 8, sort:2},
            {name: "emclient", id: 11, sort:3},
            {name: "onlyoffice", id: 12, sort:1},
            {name: "collabora", id: 13, sort:1}
        ];

        vm.marketplaceSideMenuItems = [];
        //const overviewSref = vm.isAdmin ? "index.domain-marketplace.overview" : "index.marketplace.overview";
        vm.marketplaceSideMenuItems = [
            {title: $translate.instant("OVERVIEW"), sref: vm.isAdmin ? "index.domain-marketplace.overview" : "index.marketplace.overview"},
            {title: $translate.instant("DOCUMENT_MANAGEMENT"), sref: vm.isAdmin ? "index.domain-marketplace.document_management" : "index.marketplace.document_management"},
            {title: $translate.instant("FILE_STORAGE"), sref: vm.isAdmin ? "index.domain-marketplace.file_storage" : "index.marketplace.file_storage"},
        ];
        if (vm.isAdmin) {
            vm.marketplaceSideMenuItems.push({
                title: $translate.instant("EMAIL_CLIENT"),
                sref: "index.domain-marketplace.email_client"
            });
        }

        function onTreeStateChanged(event, data) {
            vm.treeExpanded = data.expanded;
            $timeout(function () {
                $(window).trigger("resize");
            }, 250);
        }
        

        activate();

        async function activate() {
            vm.sort = $state.current.params.sort;
            $scope.$on("treeState:stateChange", onTreeStateChanged);
            vm.currentState = $state.current.params.sort;
            vm.marketplaceItems = [];
            await coreData.init;
            $rootScope.$on("themeService:changed", setBannerImage);
            vm.enableOnlyOffice = await vm.isOnlyOfficeEnabled();
            vm.enablewopi = await isCollaboraOnlineEnabled();
            setBannerImage();
            UpdateConnectedServices();
            await loadMarketplaceSettings();
            $scope.$applyAsync();
            UpdateConnectedServices();
            vm.isInitialized = true;
        }

        function UpdateConnectedServices() {
            vm.configuredAccounts = [];
            return $http.get('/api/v1/settings/connected-services')
                .then(function (response) {
                    if (response.data.connectedService) {
                        const services = response.data.connectedService;
                        for (let i = 0; i < services.length; i++) {
                            for (let j = 0; j < vm.accountTypes.length; j++) {
                                if (services[i].type === vm.accountTypes[j].id) {
                                    vm.configuredAccounts.push(vm.accountTypes[j].name);
                                }
                            }
                        }
                    }
                    vm.configuredAccounts = [...new Set(vm.configuredAccounts)];
                });
        }

        function setBannerImage() {
            if (themesService.isThemeDark()) {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_dark.webp";
                vm.lightordark = "dark";
            } else {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_light.webp";
                vm.lightordark = "light";
            }
        }

        // Check if OnlyOffice is enabled
        async function isOnlyOfficeEnabled() {
            const apiUrl = "/api/v1/marketplace/IsOnlyOfficeEnabled";
            return $http.get(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    console.error("Failed to fetch OnlyOffice settings", error);
                    return false;
                });
        }

        // Check if Collabora is enabled
        async function isCollaboraOnlineEnabled() {
            const apiUrl = "/api/v1/marketplace/IsCollaboraOnlineEnabled";
            return $http.get(apiUrl)
                .then(response => response.data)
                .catch(error => {
                    console.error("Failed to fetch Collabora settings", error);
                    return false;
                });
        }
        

        // Load marketplace settings from the backend and build the list dynamically
        function loadMarketplaceSettings() {
            $rootScope.spinner = $rootScope.spinner || { show: angular.noop, hide: angular.noop };
            $rootScope.spinner.show();

            return $http.get("/api/v1/marketplace/getmarketplace")
                .then(function (response) {
                    const marketplaceSettings = response.data || {};
                    vm.domainAdminCanOverride = marketplaceSettings.domainAdminCanOverride || false;

                    if (!coreData.user.isDomainAdmin) {
                        vm.domainAdminCanOverride = false;
                    }

                    // Initialize marketplaceItems to a fresh array
                    vm.marketplaceItems = [];

                    // If Collabora / OnlyOffice is enabled, ensure they're in configuredAccounts
                    if (vm.enablewopi) {
                        vm.configuredAccounts.push("collabora");
                    }
                    if (vm.enableOnlyOffice) {
                        vm.configuredAccounts.push("onlyoffice");
                    }

                    Object.entries(marketplaceSettings).forEach(([key, value]) => {
                        if (key.endsWith("Enabled")) {
                            const itemId = key.replace("Enabled", "");
                            const parsedName = itemId.toUpperCase();
                            let currentEnabledState = false;

                            if (itemId.toLowerCase() === "onlyoffice") {
                                currentEnabledState = vm.enableOnlyOffice;
                            } else if (itemId.toLowerCase() === "collabora") {
                                currentEnabledState = vm.enablewopi;
                            } else {
                                currentEnabledState = !!value;
                            }

                            const imageUrl = `img/MarketPlace/icon_${itemId.toLowerCase()}.webp`;
                            const name = $translate.instant(parsedName) || parsedName;
                            const description = $translate.instant(parsedName + "_DESCRIPTION") || "";

                            if (vm.sort !== 0 && vm.sort) {
                                vm.accountTypes.sort((a, b) => a.sort - b.sort);
                                // Then filter only the items that match the current sort value
                                const filteredAccountTypes = vm.accountTypes.filter(a => a.sort === vm.sort);

                                for (let i = 0; i < filteredAccountTypes.length; i++) {
                                    if (itemId.toLowerCase() === filteredAccountTypes[i].name) {
                                        vm.marketplaceItems.push({
                                            id: itemId,
                                            name: name,
                                            description: description,
                                            imageUrl: imageUrl,
                                            allowed: !!value,
                                            enabled: currentEnabledState
                                        });
                                    }
                                }
                            }else{
                            vm.marketplaceItems.push({
                                id: itemId,
                                name: name,
                                description: description,
                                imageUrl: imageUrl,
                                allowed: !!value,
                                enabled: currentEnabledState
                            });
                        }}
                    });

                    // filter out emclient
                    vm.marketplaceItems = vm.marketplaceItems.filter(item =>
                        item.id.toLowerCase() !== "emclient"
                    );
                })
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);
        }


        function openSettingsModal(item) {
            // Distinguish cloud storage from modular config
            if (["googledrive", "dropbox", "onedrive", "leitzcloud", "vboxx"].includes(item.id.toLowerCase())) {
                // Cloud Storage Connection modal
                $mdDialog.show({
                    controller: "userCloudStorageConnectController",
                    controllerAs: "cloudCtrl",
                    templateUrl: "app/marketplace/modals/user-cloud-storage-connect.modal.html",
                    clickOutsideToClose: true,
                    locals: {
                        providerName: item.name,
                        providerId: item.id,
                        enabled: item.enabled,
                        alreadyConfigured: vm.configuredAccounts.map(account => account.toLowerCase()).includes(item.id.toLowerCase()),
                        DomainAdminCanOverride: vm.domainAdminCanOverride
                    }
                })
                    .then(function () {
                        // This block runs only if the modal calls $mdDialog.hide()
                        $rootScope.spinner.show();
                        UpdateConnectedServices()
                            .then(loadMarketplaceSettings)
                            .then(function () {
                                // Force an immediate UI update
                                $scope.$applyAsync();
                            })
                            .catch(errorHandling.report)
                            .finally($rootScope.spinner.hide);
                    })
                    .catch(function () {
                         $rootScope.spinner.show();
                        UpdateConnectedServices()
                            .then(loadMarketplaceSettings)
                            .then(function () {
                                // Force an immediate UI update
                                $scope.$applyAsync();
                            })
                            .catch(errorHandling.report)
                            .finally($rootScope.spinner.hide);
                    });

            } else {
                // Modular configuration modal
                $mdDialog.show({
                    controller: "modularConfigurationModalController",
                    controllerAs: "settingsCtrl",
                    templateUrl: "app/marketplace/modals/modular-configuration.modal.html",
                    clickOutsideToClose: true,
                    locals: {
                        addonName: item.id,
                        enabled: item.enabled,
                        alreadyConfigured: vm.configuredAccounts.includes(item.id),
                        domainAdminCanOverride: vm.domainAdminCanOverride
                    }
                })
                    .then(function (updatedSettings) {
                        if (!updatedSettings) return;
                        $rootScope.spinner.show();

                        // Decide the API URL & payload
                        let apiUrl, payload;
                        switch (item.id.toLowerCase()) {
                            case "owncloud":
                                if (updatedSettings) {
                                    apiUrl = "/api/v1/settings/owncloud-put";
                                    payload = updatedSettings;
                                }
                                break;
                        }
                        if (!apiUrl || !payload) {
                            $rootScope.spinner.hide();
                            return;
                        }

                        // Save settings
                        $http.post(apiUrl, payload)
                            .then(function () {
                                if (typeof payload.enabled !== "undefined") {
                                    item.enabled = payload.enabled;
                                }
                                // After saving, re-fetch everything
                                return UpdateConnectedServices();
                            })
                            .then(loadMarketplaceSettings)
                            .then(function () {
                                $scope.$applyAsync();
                            })
                            .catch(function (error) {
                                console.error(`Failed to save settings for addon: ${item.id}`, error);
                                errorHandling.report(error);
                            })
                            .finally($rootScope.spinner.hide);
                    }, function (error) {
                        activate();
                    })
            }
        }
    }
})();
