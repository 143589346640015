(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("modularConfigurationModalController", modularConfigurationModalController);

    modularConfigurationModalController.$inject = [
        "$mdDialog",
        "$rootScope",
        "$translate",
        "$http",
        "errorHandling",
        "addonName",
        "enabled",
        "alreadyConfigured",
        "domainAdminCanOverride"
    ];

    function modularConfigurationModalController(
        $mdDialog,
        $rootScope,
        $translate,
        $http,
        errorHandling,
        addonName,
        enabled,
        alreadyConfigured,
        domainAdminCanOverride) {
        const vm = this;
        vm.initialized = false;
        vm.addonName = addonName; // "owncloud" (passed in or set as needed)
        vm.activeSections = [];
        vm.addonEnabled = enabled;
        vm.errorMessage = null;
        vm.cancel = cancel;
        vm.save = save;
        vm.alreadyConfigured = alreadyConfigured;
        vm.disableOwncloudConnection = disableOwncloudConnection;
        vm.domainAdminCanOverride = domainAdminCanOverride;
        vm.closeRefresh = closeRefresh;
        vm.needsConfig = true;


        async function activate() {
            if (vm.addonName === "onlyoffice" || vm.addonName === "collabora") {
                vm.alreadyConfigured = false;
            }
            await initializeOwnCloudSettings();
            vm.initialized = true;
            $rootScope.$applyAsync();
        }

        // Hard-code or detect OwnCloud fields for your UI
        async function initializeOwnCloudSettings() {
            // If your logic confirms it’s already configured:
            if (await isOwncloudConnected() && vm.addonName.toLowerCase() === "owncloud") {
                vm.alreadyConfigured = true;
            }
            vm.activeSections = [];
            switch (vm.addonName.toLowerCase()) {
                case "owncloud":

                    vm.activeSections = [
                        {
                            title: $translate.instant("OWNCLOUD"),
                            needsConfig: true,
                            settings: [
                                {
                                    key: "name",
                                    type: "input",
                                    label: $translate.instant("DISPLAY_NAME"),
                                    value: ""
                                },
                                {
                                    key: "url",
                                    type: "url",
                                    label: $translate.instant("SERVER_ADDRESS"),
                                    value: ""
                                },
                                {
                                    key: "username",
                                    type: "input",
                                    label: $translate.instant("USERNAME"),
                                    value: ""
                                },
                                {
                                    key: "password",
                                    type: "password",
                                    label: $translate.instant("PASSWORD"),
                                    value: ""
                                }
                            ]
                        }
                    ];
                    break;
                case "collabora":
                    vm.activeSections = [{
                        title: $translate.instant("COLLABORA"),
                        needsConfig: false
                    }
                    ];
                    break;
                case "onlyoffice":
                    vm.activeSections = [{
                        title: $translate.instant("ONLYOFFICE"),
                        needsConfig: false
                    }];
                    break;
            }
            try {
                vm.title = vm.activeSections[0].title;
                vm.needsConfig = vm.activeSections[0].needsConfig;
            } catch (e) {
                //prob collabora or Onlyoffice
            }
        }

        function closeRefresh() {
            $rootScope.$broadcast("refresh");
            $mdDialog.cancel();
        }

        // Check if OwnCloud is connected
        async function isOwncloudConnected() {
            return $http.get("/api/v1/settings/connected-services")
                .then(function (response) {
                    if (response.data.success && Array.isArray(response.data.connectedService)) {
                        return response.data.connectedService.some(service => service.type === 10);
                    }
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }

        // Obtain the existing OwnCloud service ID
        async function getOwncloudID() {
            return $http.get("/api/v1/settings/connected-services")
                .then(function (response) {
                    if (response.data.success && Array.isArray(response.data.connectedService)) {
                        const svc = response.data.connectedService.find(s => s.type === 10);
                        return svc ? svc.id : null;
                    }
                    return null;
                })
                .catch(function () {
                    return null;
                });
        }
        
        async function disableOwncloudConnection() {
            const id = await getOwncloudID();
            if (!id) return false;

            const data = {serviceIDs: [id]};
            await $http.post("/api/v1/settings/connected-services-delete", data)
                .then(function (response) {
                    if (response.data.success) {
                        closeRefresh();
                        return true;
                    }
                    return false;
                }, function () {
                    errorHandling.report("ERROR_DELETING_CLOUD_STORAGE_CONNECTION");
                    return false;
                })
                .catch(function () {
                    return false;
                });
        }

        // Attempt to verify or save OwnCloud settings
        function checkOwncloudSettings(settings) {
            const testParams = {
                url: settings.ownCloud.url,
                username: settings.ownCloud.username,
                password: settings.ownCloud.password,
                name: settings.ownCloud.name
            };
            $http.post("/api/v1/settings/owncloud-test", testParams)
                .then((result) => {
                    if (!result.data.success) {
                        errorHandling.report("CHECK_DETAILS");
                    } else {
                        $mdDialog.hide(testParams);
                    }
                })
                .catch(() => {
                    errorHandling.report("CHECK_DETAILS");
                });
        }

        function processSectionSettings(settings, section, key) {
            section.settings.forEach(field => {
                settings[key][field.key] = field.value;
            });
            // Save/verify
            checkOwncloudSettings(settings);
        }

        function save() {
            // Build the object to submit
            const settings = {
                ownCloud: {
                    enabled: vm.addonEnabled
                }
            };

            // Fill from user input
            vm.activeSections.forEach(section => {
                if (section.title === $translate.instant("OWNCLOUD")) {
                    processSectionSettings(settings, section, "ownCloud");
                }
            });
        }

        function cancel() {
            $mdDialog.cancel();

        }

        activate();

    }
})();