(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSettingsExternalAccountsController", userSettingsExternalAccountsController);

	function userSettingsExternalAccountsController($rootScope, $scope, $filter, $state, $mdDialog, $http, $q,
		$timeout, $translate, coreData, coreDataSettings, userDataService, synchronizedDeviceInfo,
		errorHandling, successHandling, externalEmailService, userTimeService) {

		var vm = this;
		vm.smtpAccounts = [];
		vm.retrievalAccounts = [];
		vm.retrievalAllowed = false;
		vm.cloudStorageEnabled = false;
		vm.migrationStarting = false;
		vm.cancelled = false;
		vm.deviceTypes = ["ACTIVESYNC", "ADD_TO_OUTLOOK", "CALDAV", "CARDDAV", "EWS", "STSSYNC", "MAPI"];
		vm.connectedDevices = [];
		vm.canRemoteWipe = false;
		vm.migrationSpinningUp = externalEmailService.migrationSpinningUp;
		vm.authenticatedIps = [];
		vm.isEmpty = true;

		// functions
		vm.editSmtpAccount = editSmtpAccount;
		vm.newRetrievalAccount = newRetrievalAccount;
		vm.editRetrievalAccount = editRetrievalAccount;
		vm.popupMigration = popupMigration;
		vm.abortMigration = abortMigration;
		vm.editDevice = editDevice;
		vm.deleteDevices = deleteDevices;
		vm.resetMigrationState = resetMigrationState;
        vm.resyncUserDevices = resyncUserDevices;

		activate();

		/////////////////////

		function activate() {
			$scope.$watch(
				function () { return externalEmailService.migrationSpinningUp; },
				function (newVal, oldVal) { vm.migrationSpinningUp = newVal; }
            );

			coreDataSettings
				.init()
				.then(function () {
					$scope.$on("migrationRefresh", onMigrationRefreshNotification);
					//$scope.$on("migrationStarted", checkRunningMigration);
					$scope.$on("migrationStarting", onMigrationStartingNotification);
					$scope.$on("migrationError", onMigrationErrorNotification);

					checkRunningMigration();

					var promises = [
						$http.get("~/api/v1/settings/retrieval-accounts"),
						$http.get("~/api/v1/settings/user-smtp-accounts"),
						$http.get("~/api/v1/settings/authenticated-ips"),
						userDataService.init()
					];

					$q.all(promises)
						.then(function(success) {
							vm.retrievalAccounts = success[0].data.accounts || [];
							vm.retrievalAccounts.forEach((acct) => {
								if (!acct.lastRetrievalUTC)
									return;
								userTimeService
									.convertLocalToUserTime(acct.lastRetrievalUTC)
									.then(function(date) {
											if (date.getSeconds() >= 30)
												date.setMinutes(date.getMinutes() + 1);
											date.setSeconds(0);

											acct.lastRetrieved = date;
										},
										function() {}
									);
							});

							vm.smtpAccounts = success[2].data.smtpAccounts || [];
							vm.authenticatedIps = success[3].data.authenticatedIps || [];
							vm.isEmpty = Object.keys(vm.authenticatedIps).length > 0 ? false : true;
							angular.forEach(vm.authenticatedIps, function (collection) {
								angular.forEach(collection, function (item) {
									item.lastLogin = $filter('date')(item.lastLogin, 'short');
									if (!item.location || item.location == "N/A")
										item.location = null;
									else if (item.location.length == 2)
										item.location = $translate.instant("COUNTRY_CODE_" + item.location);
								})
							});
							vm.retrievalAllowed = true;
							vm.cloudStorageEnabled = coreData.user.settings.features.enableCloudStorage;
							vm.canRemoteWipe = coreDataSettings.userPermissions.enableRemoteWipe;
						}, errorHandling.report);

					coreDataSettings.settingsData.userSyncDevices
						.then(function (success) {
							synchronizedDeviceInfo.connectedDevices = success;
							vm.connectedDevices = synchronizedDeviceInfo.connectedDevices;
							synchronizedDeviceInfo.loadCards = function () {
								coreDataSettings.settingsData.userSyncDevices
									.then(function () { vm.connectedDevices = synchronizedDeviceInfo.connectedDevices; }, errorHandling.report);
							};
						}, errorHandling.report);
				}, function () {});
		}

		////////////////////////////////////////////////////////////////////////////////////

		function onMigrationRefreshNotification() {
			vm.migrationStarting = false;
			vm.migrationStatus = coreDataSettings.settingsData.migrationStatus;
			if (vm.migrationStatus.doneImporting && !vm.cancelled) {
				$http
					.post("~/api/v1/settings/migration-complete")
					.then(function () { vm.cancelled = true; resetMigrationState(); }, function () { vm.cancelled = true; resetMigrationState(); });
			} else if (vm.migrationStatus.doneImporting && vm.cancelled) {
				vm.migrationStatus = null;
			}
			else if (!coreDataSettings.migrationID) {$http
				.get("~/api/v1/settings/migrations")
				.then(function (result) {
					if (result.data.migrations && result.data.migrations.length)
						coreDataSettings.migrationID = result.data.migrations[0].id;
				}, function() {});
			}
			$scope.$applyAsync();
		}

		function onMigrationErrorNotification(e, args) {
			vm.migrationStarting = false;
			vm.migrationError = true;
			vm.migrationErrorMessage = args.message;
		}

		function onMigrationStartingNotification() {
			vm.migrationStarting = true;
			vm.cancelled = false;
			vm.migrationCompleted = false;
		}

		function checkRunningMigration() {
			return $http
				.get("~/api/v1/settings/migration-status")
				.then(function (result) {
					vm.migrationStarting = false;

					if (!result.data.status) {
						coreDataSettings.resetMigrationStatus();
						return;
					}

					var isDone = true;
					if ((result.data.status.status["email"] && !result.data.status.status["email"].done) ||
						(result.data.status.status["calendar"] && !result.data.status.status["calendar"].done) ||
						(result.data.status.status["contacts"] && !result.data.status.status["contacts"].done) ||
						(result.data.status.status["tasks"] && !result.data.status.status["tasks"].done) ||
						(result.data.status.status["notes"] && !result.data.status.status["notes"].done))
						isDone = false;
					if (isDone) {
						coreDataSettings.resetMigrationStatus();
						vm.migrationCompleted = true;
						if (!vm.cancelled) {
							vm.cancelled = true;
							$http
								.post("~/api/v1/settings/migration-complete")
								.then(resetMigrationState, resetMigrationState);
						}
						return;
					}

					var newStatus = [
						{ type: 1, count: result.data.status.status["email"] ? result.data.status.status["email"].numberImported : 0 },
						{ type: 2, count: result.data.status.status["calendar"] ? result.data.status.status["calendar"].numberImported : 0 },
						{ type: 3, count: result.data.status.status["contacts"] ? result.data.status.status["contacts"].numberImported : 0 },
						{ type: 4, count: result.data.status.status["tasks"] ? result.data.status.status["tasks"].numberImported : 0 },
						{ type: 5, count: result.data.status.status["notes"] ? result.data.status.status["notes"].numberImported : 0 }
					];
					coreDataSettings.settingsData.migrationStatus = newStatus;
					vm.migrationStatus = coreDataSettings.settingsData.migrationStatus;

					$http
						.get("~/api/v1/settings/migrations")
						.then(function (result) {
							if (result.data.migrations && result.data.migrations.length)
								coreDataSettings.migrationID = result.data.migrations[0].id;
						}, function() { });
				}, function() { });
		}

		function abortMigration() {
			const confirm = $mdDialog.confirm({
				title: $translate.instant("CONFIRMATION_REQUIRED"),
				textContent: $translate.instant("MAILBOXMIGRATION_CANCEL_CONFIRMATION_TEXT"),
				ok: $translate.instant("CANCEL_MIGRATION"),
				cancel: $translate.instant("CONTINUE_MIGRATION")
			});
			$mdDialog
				.show(confirm)
				.then(
					function () {
						if (vm.abortingMigration)
							return;

						if (coreDataSettings.migrationID === undefined) {
							coreDataSettings.resetMigrationStatus();
							minTimePromise(2000).then(function () {
								vm.migrationStatus = null;
							});
							return;
						}

						$http
							.post("~/api/v1/settings/kill-migration/" + coreDataSettings.migrationID)
							.then(function () {
								coreDataSettings.resetMigrationStatus();
								minTimePromise(2000).then(function () {
									vm.migrationStatus = null;
								}, function () {});
								vm.cancelled = true;
							}, function () {
								coreDataSettings.resetMigrationStatus();
								minTimePromise(2000).then(function () {
									vm.migrationStatus = null;
								}, function () { });
							});
					},
					function() { });
		}
		
		function resetMigrationState() {
			coreDataSettings.resetMigrationStatus();
			minTimePromise(2000).then(function () {
				vm.migrationStatus = null;
			});
		}

		function resyncUserDevices() {
            var confirm = $mdDialog.confirm()
                .title($filter("translate")("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_RESYNC_USER_DEVICES"))
                .cancel($filter("translate")("CANCEL"))
                .ok($filter("translate")("CONTINUE"));

            $mdDialog.show(confirm).then(function(option) { doResyncUser(); });

            function doResyncUser() {
                $rootScope.spinner.show();
                $http.post('~/api/v1/settings/resync-user-devices')
                    .then(function(success) {
                        successHandling.report($translate.instant("RESYNC_COMPLETE_USER_NOTIFICATION"));
                    }, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
		}

		function cleanFaiUserMessages() {
			var confirm = $mdDialog.confirm()
				.title($filter("translate")("CONFIRMATION_REQUIRED"))
				.textContent($translate.instant("CONFIRMATIONS_CLEAN_FAI_USER"))
				.cancel($filter("translate")("CANCEL"))
				.ok($filter("translate")("CONTINUE"));

			$mdDialog.show(confirm).then(function (option) { doCleanFaiUser(); });

			function doCleanFaiUser() {
				$rootScope.spinner.show();
				$http.post('~/api/v1/settings/clean-user-fai')
					.then(function (success) {
						successHandling.report($translate.instant("CLEAN_FAI_COMPLETE_USER_NOTIFICATION"));
					}, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}
		}

		function minTimePromise(val) {
			var deferred = $q.defer();
			$timeout(function () { deferred.resolve(); }, val || 500);
			return deferred.promise;
		}

		function popupMigration() {
			$mdDialog
				.show({
					locals: { account: null, isMigration: true },
					controller: "externalEmailDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/user-settings/external-accounts/external-email.dlg.html"
				})
				.then(function () {
					vm.migrationStarting = false;

					var newStatus = [
						{ type: 1, count: 0 },
						{ type: 2, count: 0 },
						{ type: 3, count: 0 },
						{ type: 4, count: 0 },
						{ type: 5, count: 0 }
					];
					coreDataSettings.settingsData.migrationStatus = newStatus;
					vm.migrationStatus = coreDataSettings.settingsData.migrationStatus;

				}, function () { })
				.finally(function () {
					vm.migrationError = false;
					$state.go("index.settings.advanced", { page: "" });
				});
		}

		////////////////////////////////////////////////////////////////////////////////////

		function newRetrievalAccount(event) {
			var account = {
				isManualRetrieval: true
			};
			$mdDialog
				.show({
					locals: { account: null, isMigration: false },
					controller: "externalEmailDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/user-settings/external-accounts/external-email.dlg.html",
					targetEvent: event
				})
				.then(function(result) { saveRetrievalModalChanges(result, account); }, function() {});
		}

		function editRetrievalAccount(event, item) {
			var account = item;
			$mdDialog
				.show({
					locals: { account: angular.copy(account), isMigration: false },
					controller: "externalEmailDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/user-settings/external-accounts/external-email.dlg.html",
					targetEvent: event
				})
				.then(function(result) { saveRetrievalModalChanges(result, account); }, function() {});
		}

		function saveRetrievalModalChanges(modalSuccess, account) {
			if (modalSuccess.deleteItem) {
				var confirm = $mdDialog.confirmDeletion().textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }));
				$mdDialog
					.show(confirm)
					.then(
						function () {
							$http
								.post("~/api/v1/settings/retrieval-accounts-delete", JSON.stringify({ ids: [account.id] }))
								.then(refreshRetrievalAccounts, errorHandling.report);
						},
						function () { }
					);
				return;
			}
			if (modalSuccess.processing) {
				account.isProcessing = true;
				return;
			}

			account.serverAddress = modalSuccess.serverAddress;
			account.username = modalSuccess.username;
			account.emailAddress = modalSuccess.username;
			account.password = modalSuccess.password;
			account.serverPort = modalSuccess.serverPort;
			account.encryption = modalSuccess.encryption;
			account.deleteEverythingBeforeImport = modalSuccess.deleteEverythingBeforeImport;
			account.downloadToFolder = modalSuccess.downloadToFolder;
			account.enableSpamFilter = modalSuccess.enableSpamFilter;
			account.isManualRetrieval = modalSuccess.isManualRetrieval;
			account.folderTransferMethod = modalSuccess.folderTransferMethod;
			account.leaveMailOnServer = modalSuccess.leaveMailOnServer;
			account.useApop = modalSuccess.useApop;
			account.accountType = modalSuccess.accountType;
			account.accountTypeDescription = modalSuccess.accountTypeDescription;
			account.oauthAccount = modalSuccess.oauthAccount;
			account.oauthAccessToken = modalSuccess.oauthAccessToken;
			account.oauthRefreshToken = modalSuccess.oauthRefreshToken;
			account.oauthTokenExpires = modalSuccess.oauthTokenExpires;
			account.itemsToImport = 1;		// Exchange retrieval accounts need to be marked for email retrieval.
			
			if (account.id) {
				$http
					.post("~/api/v1/settings/retrieval-account", JSON.stringify({ retrievalAccount: account }))
					.then(function (success) { account.id = success.data.id || account.id; }, errorHandling.report);
			} else if (account.accountType === "IMAP") {
				$http.post("~/api/v1/settings/imap-account-put", JSON.stringify({ imapAccount: account }))
					.then(
						function (success) {
							account.id = success.data.id;
							vm.retrievalAccounts.push(account);
							startNewAccount(account);
						},
						errorHandling.report);
			} else if (account.accountType === "POP") {
				$http.post("~/api/v1/settings/pop-account-put", JSON.stringify({ popAccount: account }))
					.then(
						function (success) {
							account.id = success.data.id;
							vm.retrievalAccounts.push(account);
							startNewAccount(account);
						},
						errorHandling.report);
			} else {
				$http.post("~/api/v1/settings/exchange-account-put", JSON.stringify({ exchangeAccount: account }))
					.then(
						function (success) {
							account.id = success.data.id;
							vm.retrievalAccounts.push(account);
							startNewAccount(account);
						},
						errorHandling.report);
			}

			function startNewAccount(acct) {
				if (account.isManualRetrieval) {
					externalEmailService.startRetrieval(acct)
						.then(
							function () {
								acct.isProcessing = true;
								successHandling.report("RETRIEVE_PROCESS_RUNNING");
							},
							errorHandling.report
						);
				} else {
					acct.isProcessing = true;
					successHandling.report("RETRIEVE_PROCESS_RUNNING");
				}
			}
		}

		function refreshRetrievalAccounts() {
			$http
				.get("~/api/v1/settings/retrieval-accounts")
				.then(function (success) {
					vm.retrievalAccounts = success.data.accounts;
				},
					errorHandling.report);
		}

		////////////////////////////////////////////////////////////////////////////////////

		function newSmtpAccount(event) {
			$mdDialog.show({
				locals: { card: { port: 25, encryptionType: 0 }, newItem: true },
				controller: "userEditSmtpAccountsController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/external-accounts/smtp-account.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					const params = JSON.stringify({
						smtpAccount: {
							address: modalSuccess.address,
							port: modalSuccess.port,
							displayName: modalSuccess.displayName,
							emailAddress: modalSuccess.emailAddress,
							userName: modalSuccess.userName,
							password: modalSuccess.password,
							encryptionType: modalSuccess.encryptionType,
							useAuthentication: modalSuccess.useAuthentication,
							oauthCode: modalSuccess.oauthCode,
							oauthAccessToken: modalSuccess.oauthAccessToken,
							oauthRefreshToken: modalSuccess.oauthRefreshToken,
							oauthAccount: modalSuccess.oauthAccount,
							oauthTokenExpires: modalSuccess.oauthTokenExpires
						}
					});

					$http.post("~/api/v1/settings/user-smtp-account-add", params )
						.then(refreshSmtpAccounts, errorHandling.report);
				}, function () { });
		}

		function editSmtpAccount(event, item) {
			$mdDialog.show({
				locals: { card: angular.copy(item), newItem: false },
				controller: "userEditSmtpAccountsController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/external-accounts/smtp-account.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					if (modalSuccess.deleteItem) {
						const confirm = $mdDialog.confirmDeletion()
							.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: 1 }))
							.targetEvent(event);
						$mdDialog.show(confirm).then(function () {
							const params = JSON.stringify({ idsToDelete: [item.id] });
							$http.post("~/api/v1/settings/user-smtp-accounts-delete", params)
								.then(refreshSmtpAccounts, errorHandling.report);
						}, function () { });
					} else {
						const tempItem = angular.copy(item);
						tempItem.address = modalSuccess.address;
						tempItem.port = modalSuccess.port;
						tempItem.displayName = modalSuccess.displayName;
						tempItem.emailAddress = modalSuccess.emailAddress;
						tempItem.userName = modalSuccess.userName;
						tempItem.password = modalSuccess.password;
						tempItem.encryptionType = modalSuccess.encryptionType;
						tempItem.useAuthentication = modalSuccess.useAuthentication;
						tempItem.oauthCode = modalSuccess.oauthCode;
						tempItem.oauthAccessToken = modalSuccess.oauthAccessToken;
						tempItem.oauthRefreshToken = modalSuccess.oauthRefreshToken;
						tempItem.oauthAccount = modalSuccess.oauthAccount;
						tempItem.oauthTokenExpires = modalSuccess.oauthTokenExpires;
						const params = JSON.stringify({ smtpAccount: tempItem });
						
						$http.post("~/api/v1/settings/user-smtp-account-update", params)
							.then(refreshSmtpAccounts, errorHandling.report);
					}
				}, function () { });
		}

		function saveSmtpAccounts(item, isEditing, index) {
			var accountsWithAdded = vm.smtpAccounts.slice();
			if (!isEditing) {
				accountsWithAdded.push(item);
			} else if(index != null && index != -1) {
				accountsWithAdded[index] = item;
			}
			$http
				.post("~/api/v1/settings/user-smtp-accounts", JSON.stringify({ smtpAccounts: accountsWithAdded }))
				.then(function () {
					if (!isEditing) {
						vm.smtpAccounts.push(item);
					} else {
						if (index > -1) {
							vm.smtpAccounts[index].address = item.address;
							vm.smtpAccounts[index].port = item.port;
							vm.smtpAccounts[index].displayName = item.displayName;
							vm.smtpAccounts[index].emailAddress = item.emailAddress;
							vm.smtpAccounts[index].userName = item.userName;
							vm.smtpAccounts[index].password = item.password;
							vm.smtpAccounts[index].encryptionType = item.encryptionType;
							vm.smtpAccounts[index].useAuthentication = item.useAuthentication;
						}
					}
					refreshSmtpAccounts();
					$rootScope.$broadcast("user-settings:changed");
				}, errorHandling.report);
		}

		function refreshSmtpAccounts() {
			$http
				.get("~/api/v1/settings/user-smtp-accounts")
				.then(function (success) {
					vm.smtpAccounts = success.data.smtpAccounts;
				}, errorHandling.report);
		}

		////////////////////////////////////////////////////////////////////////////////////

		function newFileStorageProvider(event) {
			$mdDialog.show({
				controller: "userCloudStorageConnectController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/external-accounts/cloud-storage-connect.dlg.html",
				targetEvent: event,
				locals: {
					showAllOptions: event.ctrlKey || false
				}
			})
				.then(addProvider, function (error) {
					if (error != undefined && error.reason != undefined)
						if (error.reason === "cancel")
							return;

					errorHandling.report(error);
				});

			
		}
		
		

		////////////////////////////////////////////////////////////////////////////////////

		function editDevice(selectedCard, ev) {
			var params = JSON.stringify({ deviceId: selectedCard.deviceId, type: selectedCard.syncType });
			$http
				.post("~/api/v1/settings/sync-device", params)
				.then(function (success) {
					if (success.data && success.data.devices && success.data.devices.length > 0) {
						var id = selectedCard.id;
						selectedCard = success.data.devices[0];
						selectedCard.lastSyncTimeUTC = moment(selectedCard.lastSyncTimeUTC);
						selectedCard.id = id;
					}

					if (!coreDataSettings.selectMode) {
						$mdDialog.show({
							locals: {
								friendlyName: selectedCard.name ? selectedCard.name : undefined,
								description: selectedCard.description ? selectedCard.description : undefined,
								type: vm.deviceTypes[selectedCard.syncType],
								ip: selectedCard.ip ? selectedCard.ip : undefined,
								deviceId: selectedCard.deviceId ? selectedCard.deviceId : undefined,
								userAgent: selectedCard.userAgent ? selectedCard.userAgent : undefined,
								rssLink: selectedCard.rssLink ? selectedCard.rssLink : undefined,
								lastSync: selectedCard.lastSyncTimeUTC ? selectedCard.lastSyncTimeUTC : undefined,
								remoteWipeAllowed: vm.canRemoteWipe && selectedCard.syncType === 0,
								hostname: selectedCard.hostname ? selectedCard.hostname : undefined,
								protocolVersion: selectedCard.protocolVersion ? selectedCard.protocolVersion : undefined
							},
							controller: "userSyncDeviceController",
							controllerAs: "ctrl",
							templateUrl: "app/settings/user-settings/external-accounts/edit-sync-device.dlg.html",
							targetEvent: ev
						})
							.then(function (modalSuccess) {
								if (modalSuccess.remoteWipe && vm.canRemoteWipe && selectedCard.syncType === 0)
									synchronizedDeviceInfo.activeSyncRemoteWipe(selectedCard, ev);
								else if (modalSuccess.deleteItem)
									vm.deleteDevices([selectedCard], ev);
							});
					} else {
						var index = coreDataSettings.selectedCards.indexOf(selectedCard);
						if (index > -1) {
							coreDataSettings.selectedCards.splice(index, 1);
						} else {
							coreDataSettings.selectedCards.push(selectedCard);
						}
					}
				}, function (failure) {
					if (failure != undefined && failure.reason != undefined)
						if (failure.reason === "cancel")
							return;
					errorHandling.report(failure);
				});
		}

		function deleteDevices(selectedCards, ev) {
			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: selectedCards.length }))
				.targetEvent(ev);
			$mdDialog.show(confirm)
				.then(function () { doDelete(); }, function() {});

			function doDelete() {
				angular.forEach(selectedCards, function (card) {
					switch (card.syncType) {
						case 0:
							synchronizedDeviceInfo.deleteActiveSync(card);
							break;
						case 1:
						case 5:
							synchronizedDeviceInfo.deleteStsSync(card);
							break;
						case 2:
						case 3:
							synchronizedDeviceInfo.deleteCardDavSync(card);
							break;
						case 4:
							synchronizedDeviceInfo.deleteEwsSync(card);
							break;
						case 6:
							synchronizedDeviceInfo.deleteMapiSync(card);
							break;
						default:
							errorHandling.report("CANT_DELETE_SYNC");
							break;
					}
				});
			}
		}
	}
})();