(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("domainSettingsMarketplaceSettingsController", domainSettingsMarketplaceSettingsController);

    function domainSettingsMarketplaceSettingsController(
        $scope,
        $http,
        errorHandling,
        $rootScope,
        themesService,
        $translate,
        $mdDialog,
        userDataService,
        coreData,
        coreLicensing,
        $state) {
        const vm = this;

        vm.isInitialized = false;
        vm.marketplaceItems = [];
        vm.bannerImage = "";
        vm.domainAdminCanOverride = false;
        vm.lightordark = "";
        vm.configuredAccounts = [];
        vm.reloadvar = true;

        $scope.treeExpanded = true;
        $scope.treeExpanded = true;

        vm.accountTypes = [
            {name: "dropbox", id: 2, sort:2},
            {name: "googledrive", id: 9, sort:2},
            {name: "leitzcloud", id: 7, sort:2},
            {name: "onedrive", id: 4, sort:2},
            {name: "owncloud", id: 10, sort:2},
            {name: "vboxx", id: 8, sort:2},
            {name: "emclient", id: 11, sort:3},
            {name: "onlyoffice", id: 12, sort:1},
            {name: "collabora", id: 13, sort:1}
        ];

        vm.loadMarketplaceSettings = loadMarketplaceSettings;
        vm.sortItems = sortItems;
        vm.openSettingsModal = openSettingsModal;
        vm.isOnlyOfficeEnabled = isOnlyOfficeEnabled;

        activate();

        async function activate() {
            vm.sort = $state.current.params.sort;
            await coreData.init;
            vm.domainSettings = await $http.get("~/api/v1/settings/domain/domain");
            vm.domainSettings = vm.domainSettings.data.domainSettings;
            setBannerImage();
            $rootScope.$on("themeService:changed", setBannerImage);

            vm.enableOnlyOffice = await vm.isOnlyOfficeEnabled();
            vm.enablewopi = await isCollaboraOnlineEnabled();

            updateConnectedServices();

            loadMarketplaceSettings().then(function () {
                sortItems();
                vm.isInitialized = true;
            });
        }

        function setBannerImage() {
            if (themesService.isThemeDark()) {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_dark.webp";
                vm.lightordark = "dark";
            } else {
                vm.bannerImage = "img/MarketPlace/marketplaceBanner_light.webp";
                vm.lightordark = "light";
            }
        }

        function updateConnectedServices() {
            $http.get("/api/v1/settings/connected-services").then(function (response) {
                if (response.data.connectedService && response.data.connectedService.length > 0) {
                    const services = response.data.connectedService;
                    services.forEach(function (service) {
                        vm.accountTypes.forEach(function (accType) {
                            if (service.type === accType.id) {
                                vm.configuredAccounts.push(accType.name);
                            }
                        });
                    });
                }
            });
        }

        async function isOnlyOfficeEnabled() {
            const apiUrl = "/api/v1/marketplace/IsOnlyOfficeEnabled";
            try {
                const response = await $http.get(apiUrl);
                return response.data || false;
            } catch (error) {
                console.error("Failed to fetch OnlyOffice settings", error);
                return false;
            }
        }

        async function isCollaboraOnlineEnabled() {
            const apiUrl = "/api/v1/marketplace/IsCollaboraOnlineEnabled";
            try {
                const response = await $http.get(apiUrl);
                return response.data || false;
            } catch (error) {
                console.error("Failed to fetch Collabora settings", error);
                return false;
            }
        }

        function loadMarketplaceSettings() {
            $rootScope.spinner = $rootScope.spinner || {show: angular.noop, hide: angular.noop};
            $rootScope.spinner.show();

            return $http.get("/api/v1/marketplace/getmarketplace")
                .then(function (response) {
                    const marketplaceSettings = response.data || {};
                    vm.domainAdminCanOverride = marketplaceSettings.domainAdminCanOverride || false;

                    if (vm.enablewopi) {
                        vm.configuredAccounts.push("collabora");
                    }
                    if (vm.enableOnlyOffice) {
                        vm.configuredAccounts.push("onlyoffice");
                    }

                    if (!coreData.user.isDomainAdmin) {
                        vm.domainAdminCanOverride = false;
                    }

                    vm.marketplaceItems = [];

                    Object.entries(marketplaceSettings).forEach(([key, value]) => {
                        var addItem = true;
                        if (key.endsWith("Enabled")) {
                            const itemId = key.replace("Enabled", "");
                            const parsedName = itemId.toUpperCase();
                            if (itemId.toLowerCase() === "emclient") {
                                if (checkEmClientEligibility()) {
                                    addItem = true;
                                } else {
                                    addItem = false;
                                }
                            }
                            let currentEnabledState = false;
                            if (itemId.toLowerCase() === "onlyoffice") {
                                currentEnabledState = vm.enableOnlyOffice;
                            } else if (itemId.toLowerCase() === "collabora") {
                                currentEnabledState = vm.enablewopi;
                            } else {
                                currentEnabledState = !!value;
                            }

                            const imageUrl = `img/MarketPlace/icon_${itemId.toLowerCase()}.webp`;
                            const name = $translate.instant(parsedName) || parsedName;
                            const description = $translate.instant(parsedName + "_DESCRIPTION") || "";
                            if (addItem) {
                                if (vm.sort !== 0 && vm.sort) {
                                    vm.accountTypes.sort((a, b) => a.sort - b.sort);
                                    // Then filter only the items that match the current sort value
                                    const filteredAccountTypes = vm.accountTypes.filter(a => a.sort === vm.sort);

                                    for (let i = 0; i < filteredAccountTypes.length; i++) {
                                        if (itemId.toLowerCase() === filteredAccountTypes[i].name) {
                                            vm.marketplaceItems.push({
                                                id: itemId,
                                                name: name,
                                                description: description,
                                                imageUrl: imageUrl,
                                                allowed: !!value,
                                                enabled: currentEnabledState
                                            });
                                        }
                                    }
                                }else{
                                vm.marketplaceItems.push({
                                    id: itemId, // e.g. "onlyoffice", "collabora", etc.
                                    name: name,
                                    description: description,
                                    imageUrl: imageUrl,
                                    allowed: !!value,
                                    enabled: currentEnabledState
                                });
                            }}
                        }
                    });
                })
                .catch(errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        function checkEmClientEligibility() {
            switch (true) {
                case coreLicensing.edition >= 2:
                    return false;
                case !coreLicensing.license:
                    return false;
                case coreLicensing.license.isTrialLicense:
                    return false;
                case !vm.domainSettings.emClientEnabled:
                    return false;
                case !vm.domainSettings.emClientOffersAvailable:
                    return false;
                case !vm.domainSettings.isTopLevelDomain:
                    return false;
                default:
                    return true;
            }
        }

        function sortItems() {
            vm.marketplaceItems.sort((a, b) => a.name.localeCompare(b.name));
        }


        /**
         * Opens the correct modal depending on whether this is a cloud-storage provider or a feature-based addon.
         */
        function openSettingsModal(item) {
            $mdDialog.show({
                controller: "adminConfigurationModuleController",
                controllerAs: "settingsCtrl",
                templateUrl: "app/marketplace/modals/admin-configuration-module.modal.html",
                clickOutsideToClose: false,
                locals: {
                    localAddonName: item.id,
                    localAddonId: item.id,
                    localEnabled: item.enabled,
                    localAlreadyConfigured: vm.configuredAccounts.includes(item.id),
                    localDomainAdminCanOverride: vm.domainAdminCanOverride
                }
            }).then(function (updatedSettings) {
                activate();
                if (!updatedSettings) return;

                $rootScope.spinner.show();

                let apiUrl, payload;
                switch (item.id.toLowerCase()) {
                    case "onlyoffice":
                        if (updatedSettings.onlyOffice) {
                            apiUrl = "/api/v1/marketplace/setOnlyOfficeSettings";
                            payload = updatedSettings.onlyOffice;
                        }
                        break;
                    case "collabora":
                        if (updatedSettings.wopi) {
                            apiUrl = "/api/v1/marketplace/setCollaboraSettings";
                            payload = updatedSettings.wopi;
                        }
                        break;
                    default:
                        // If your adminConfigurationModule supports other addons, handle them here
                        if (updatedSettings.cloudStorage) {
                            apiUrl = "/api/v1/marketplace/ToggleCloudStorageProvider";
                            payload = updatedSettings.cloudStorage;
                        }
                        break;
                }

                if (!apiUrl || !payload) {
                    console.warn(`No valid settings to save for addon: ${item.id}`);
                    $rootScope.spinner.hide();
                    return;
                }

                $http.post(apiUrl, payload)
                    .then(function () {
                        if (typeof payload.enabled !== "undefined") {
                            item.enabled = payload.enabled;
                        }
                    })
                    .catch(function (error) {
                        console.error(`Failed to save settings for addon: ${item.id}`, error);
                        errorHandling.report(error);
                        activate();
                    })
                    .finally($rootScope.spinner.hide);

            }).catch(function () {
                activate();
            });
        }
    }
})();
